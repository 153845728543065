:root {
  --bg: #0a0b0d;
  --dark: #0f1013;
  --light: #14161a;
  --border: #3b404a;
  --grey: #525863;
  --purple: #8b18c9;
  --blue: #0345ac;
  --gradient: linear-gradient(145deg, rgba(3, 69, 160, 1) 0%, rgba(139, 24, 201, 1) 60%);
  --white: #fff4ff;
}


@font-face {
  font-family: ExtraBold;
  src: url(./fonts/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: Bold;
  src: url(./fonts/Inter-Bold.ttf);
}

@font-face {
  font-family: Medium;
  src: url(./fonts/Inter-Medium.ttf);
}

@font-face {
  font-family: Light;
  src: url(./fonts/Inter-Light.ttf);
}


body {
  background-color: var(--bg);
  margin: 0;
  overflow-x: hidden;
  overflow-y: overlay;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}


html {

  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  background-image: url(images/backgroundfivestar.png);
  background-repeat: repeat-y;
  background-position: top;
  background-size: cover;
  min-height: 100vh;
}

@media screen and (max-width: 700px) {
  body {
    background-image: none;
  }

}


a,
ul,
li,
h1 {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: calc(100% - 6rem);
  padding: 0 3rem;
  height: 4rem;
  z-index: 999;
  position: absolute;
  top: 0;
}

.nav-item:not(.nav-title) {
  display: flex;
  align-items: center;
  margin: 0 1vw;
}

.nav-title {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  border: 0;
  cursor: pointer;
  padding: 1rem;
  font-family: Bold;
  background-color: #5865F2;
  height: 2rem;
  color: white;
}

.nav-button svg {
  margin-right: 10px;
}

.nav-title img {
  height: 3rem;
}


.nav-links {
  display: flex;
  width: 100%;
  padding: 0 5rem;
  flex-direction: row;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.nav-item:not(.nav-title) span {
  color: var(--white);
  font-size: 15px;
  margin-left: 5px;
  font-family: Bold;
}


.nav-title span {
  color: var(--white);
  font-size: 15px;
  font-family: ExtraBold;
}


.nav-links-mobile {
  display: none;
}


.mobile-btn {
  display: none;
  z-index: 99;
}

.mobile-active .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-3px, 4px);

}

.mobile-active .bar:nth-child(2) {
  display: none;
}

.mobile-active .bar:nth-child(3) {
  transform: rotate(45deg) translate(-3px, -4px);
}

.nav-links-mobile {

  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  top: 3.5rem;
  left: 0;
  height: 0;
  z-index: 99;
  background-color: var(--bg);
  mask: linear-gradient(180deg, white 10%, white 90%, transparent);
  width: 100%;
  overflow: hidden;
  transition: height ease-in-out 150ms;
}

.nav-item-mobile {
  font-family: Bold;
  color: var(--white);
  cursor: pointer;
  width: 100%;
  display: flex;
  width: 10rem;
  margin-top: 1.5rem;
  height: 3.5rem;
  z-index: 99;
  flex-direction: row;
  justify-content: center;
  background-color: var(--dark);
  align-items: center;
  border-radius: 1rem;
}

.nav-item-mobile span {
  margin-left: 5px;
}

.nav-links-mobile-active {
  height: 35rem;
}



.bar {
  width: 2rem;
  margin: 8px 0;
  height: 2px;
  background-color: var(--white);
}

@media screen and (max-width: 1200px) {
  .nav-links {
    padding: 0 .5rem;
  }

}


@media screen and (max-width: 1000px) {

  .nav-title {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    height: 2rem;
    top: 1rem;
    left: 2rem;
  }

  .nav-button {
    display: none !important;
  }

  .nav-links {
    display: none;
  }

  .nav-links-mobile {

    display: flex;
  }

  .mobile-btn-container {
    display: flex;
  }

  .mobile-btn {
    display: block;

  }

}


.hero {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  max-width: 80rem;
  width: 90%;
  margin: 10rem auto 10rem auto;
}


.hero-info {
  width: 55%;
}

.hero-title {
  color: var(--purple);
  font-family: ExtraBold;
  font-size: 45px;
  line-height: 80px;
}

.hero-sub-title {
  color: var(--white);
  font-family: Bold;
  font-size: 30px;
  margin: 1.5rem 0;
}

.hero-description {
  font-family: Medium;
  color: var(--white);
  margin-bottom: 1.5rem;
}

.text-changer {
  color: var(--purple);
}

.hero-btn {

  padding: 1rem 3rem;
  background: var(--gradient);
  border: 0;
  color: var(--white);
  font-family: Bold;
  cursor: pointer;
  border-radius: 20px;

}

.text-changer {
  color: var(--purple);
}

.text-cursor {
  color: var(--purple);
}

.flash {
  animation: flash 0.7s infinite;
}

@keyframes flash {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  50.1% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.hero-img {
  display: flex;
  align-items: center;
  height: 22rem;
}


@media screen and (max-width: 1250px) {
  .hero {
    margin: 6rem auto 10rem auto;
    justify-content: center;
  }

  .hero-info {
    position: relative;
    width: 100%;
    text-align: center;
    max-width: 40rem;
    margin-bottom: 4rem;
  }

  .hero-img img {
    display: none;
  }

  .hero-btn {
    margin: 4rem 0;
    left: 39%;
    position: absolute;
    z-index: 9999;


  }

  .hero-btn-active {

    padding: 1rem 3rem;
    background: var(--gradient);
    border: 0;
    color: var(--white);
    font-family: Bold;
    cursor: pointer;
    border-radius: 20px;
    margin: 4rem 0 !important;
    position: relative !important;
    z-index: 1 !important;
  }

}


@media screen and (max-width: 800px) {

  .hero-title {
    font-size: 40px;
    line-height: 60px;
  }

  .hero-sub-title {
    font-size: 18px;
    margin: 1rem 0;
  }

  .hero-btn {
    margin: 4rem 0;
    left: 35%;
    position: absolute;
    z-index: 9999;


  }

  .hero-description {}


}


.stats {
  margin: 0 auto 15rem auto;
  width: 50rem;
}

.stats-title {
  font-family: Bold;
  text-align: center;
  color: var(--white);
  font-size: 30px;
}

.stat-row {
  display: flex;
  margin-top: 3rem;
  justify-content: space-around;
}

.stat {
  display: flex;
  align-items: center;
  text-align: center;
  max-width: 10rem;
  flex-direction: column;
}

.stat-number {
  color: var(--purple);
  font-size: 35px;
  font-family: Bold;
}

.stat-desc {
  margin-top: 1rem;
  color: var(--white);
  font-size: 25px;
  font-family: Medium;
}

@media screen and (max-width: 800px) {

  .stats {
    margin: 0 auto 15rem auto;
    width: 90%;
  }

  .stats-title {
    font-size: 25px;
  }

  .stat-number {
    font-size: 30px;
  }

  .stat-desc {
    font-size: 20px;
  }


}



.reviews {
  max-width: 80rem;
  width: 90%;
  margin: 0 auto 15rem auto;

}

.review-title {
  color: var(--white);
  text-align: center;
  font-family: Bold;

  font-size: 30px;
  margin-bottom: 5rem;
}

.review-slider {
  display: flex;
  mask: linear-gradient(180deg, transparent, white 10%, white 90%, transparent);
  justify-content: center;
  width: 100%;
  max-height: 37rem;

  text-align: center;
  color: var(--white);
}

.review-slider-col-fast {
  height: 100%;
  animation: scroll 25s linear infinite;
}

.review-slider-col-slow {
  height: 100%;
  animation: scroll 15s linear infinite;
}

.odd {
  height: 100%;
  transform: translateY(-50%);
  animation: scrollreverse 30s linear infinite;
}


.review-slider-col-inner {

  width: 100%;


}


.review-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 23rem;
  min-width: 23rem;
  height: fit-content;
  padding: 1rem;
  margin: 1rem;
  border-radius: 1rem;
  background-color: var(--dark);
}

.review-author {
  margin-top: auto;
}




@media screen and (max-width: 1420px) {


  .review-slider-col-slow {
    display: none;
  }

}


@media screen and (max-width: 1000px) {


  .review-slider-col-fast:first-child {
    display: none;
  }

  .review-slider-col:last-child {
    display: none;
  }

}


@media screen and (max-width: 800px) {
  .review-card {
    min-width: calc(100% - 4rem);
    max-width: calc(100% - 4rem);
    margin: 1rem auto;
  }

  .review-title {
    font-size: 25px;
  }

}




@keyframes scroll {
  to {
    transform: translateY(-50%);
  }
}

@keyframes scrollreverse {
  to {
    transform: translateY(0);
  }
}

.review-stars {
  width: 100%;
  display: flex;
}

.review-text {
  font-family: Medium;
  color: var(--white);
  margin-bottom: 1rem;
  text-align: left;
  width: 100%;
  word-wrap: break-word;
}

.review-author {
  color: var(--grey);
  font-family: Light;
  width: 100%;
  text-align: left;
}


.games {
  margin: 0 auto 15rem auto;
  max-width: 85rem;
  width: 90%;

}


.games-title {
  font-family: Bold;
  text-align: center;
  color: var(--purple);
  font-size: 30px;
  margin-bottom: 5rem;
}

@media screen and (max-width: 1200px) {

  .games-title {
    text-align: center;

  }

}

@media screen and (max-width: 800px) {

  .games-title {
    font-size: 25px;

  }



}

.game-row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.game-card {
  overflow: hidden;
  height: 17rem;
  border: thin solid var(--purple);
  margin: 1rem;
  width: 17rem;
  border-radius: 1rem;
  background-color: var(--dark);
}

.card-img {
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  min-width: calc(101% + 1px);
  background-color: var(--light);
  max-height: 10rem;
  border-radius: 1rem;
}

.card-img img {
  -webkit-mask: linear-gradient(180deg, white 15%, white 70%, transparent);
  mask: linear-gradient(180deg, white 15%, white 70%, transparent);
  min-height: 100%;
}

.card-info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
  height: 6.5rem;
  width: calc(100% - 2rem + 1px);
  transform: translate(-1px, -1.3rem);
  background-color: rgba(15, 16, 19, 0.7);
  backdrop-filter: blur(3px);
  border-radius: 1rem;
  border: thin solid var(--purple);
}

.card-text {
  display: flex;
  margin: 0;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
}

.text-header {
  display: flex;
  flex-direction: column;
}

.card-cheat-container {
  width: 100%;
  display: flex;
  height: 2rem;

  justify-content: center;
  align-items: center;
}


.card-cheat {
  font-size: 16px;

  color: var(--white);
  text-align: center;
  font-family: Bold;
}

.card-price {
  font-size: 23px;
  margin-top: 3px;
  color: var(--white);
  font-family: Bold;
}

.price-container {
  display: flex;
  flex-direction: column;

}

.card-start {
  color: var(--white);
  font-family: Light;
  font-size: 14px;
}

.text-purchase {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: auto;
}


.btn-border {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gradient);
  border-radius: 1rem;
  width: 5rem;
  height: 3rem;

}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dark);
  width: calc(5rem - 2px);
  height: calc(3rem - 2px);
  border-radius: 15px;
  color: var(--white);
  font-size: 14px;
  text-align: center;
  font-family: Medium;
  transition: all ease-in-out 150ms;
}

.btn:hover {
  background-color: var(--gradient);

}



.card-btn:hover {
  border: 0;
  background: var(--gradient);
}




.media-selector {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto 15rem auto;
  max-width: 80rem;
  width: 90%;
}

.media {
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  max-width: 35rem;
  background-color: var(--dark);
  aspect-ratio: 16/9;
  overflow: hidden;
}

.media iframe {
  min-width: 35rem;
  height: 100%;
  width: 35rem;
}

.media img {
  min-width: 35rem;
  object-fit: contain;
}

.media-active {
  display: flex;

}

.selectors {
  display: flex;
  margin-left: 3rem;
  justify-content: space-around;
  height: 30rem;
  flex-direction: column;
}

.selector {
  cursor: pointer;
  border-radius: 1rem;
  width: 30rem;
  padding: 2rem;
}

.selector-active {
  outline: thin solid var(--border);
  background-color: var(--dark);
}

.selector-title {
  color: var(--white);
  font-family: Medium;
}

.selector-desc {
  color: var(--grey);
  font-family: Light;
}

@media screen and (max-width: 1300px) {
  .media-selector {
    display: flex;
    flex-direction: column;
  }

  .selectors {
    margin: 0;

  }

}

@media screen and (max-width: 600px) {

  .media-active {
    display: flex;
    margin-bottom: 1rem;
    margin-right: 0rem;
  }

  .media {
    width: 100%;
    border-radius: 10px;
  }

  .media iframe {
    max-width: 70%;
    min-width: 400px;
    height: 100%;
  }

  .selector {
    cursor: pointer;
    border-radius: 1rem;
    width: calc(100% - 2rem);
    padding: 1rem;
  }

  .selectors {

    height: 20rem;
  }

  .selector-title {
    font-size: 15px;
  }

  .selector-desc {
    font-size: 13px;
  }


}

.images {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15rem auto;
  max-width: 80rem;
  width: 90%;
}


.image-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;


  margin: 4rem 0;
}

.img {
  background-color: var(--dark);
  aspect-ratio: 16/9;
  max-width: 38rem;
  overflow: hidden;
  width: 38rem;
  height: calc(38rem * 0.5625);
  border-radius: 1rem;

}

.img iframe {
  min-width: 100%;
  min-height: 100%;
}

.img img {
  object-fit: cover;
  min-width: 100%;
}



.img-left {
  margin-left: 3rem;
}

.img-right {
  margin-right: 3rem;
}

.img-text {
  width: 40%;
  margin-bottom: 3rem;
  min-width: 30rem;
}

.img-title {
  color: var(--white);
  font-family: Medium;
  font-size: 25px;
  margin-bottom: 1rem;
}

.img-desc {
  color: var(--grey);
  font-family: Light;
  font-size: 15px;
}

.img-checks {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}

.img-checks span {
  display: flex;
  align-items: center;
  font-family: Medium;
  color: var(--grey);
  margin-right: 1.5rem;
  margin-top: 1rem;
}

.img-checks span svg {
  color: var(--purple);
  height: 25px;
}

.title-left {
  text-align: right;
}

.desc-left {
  text-align: right;
}

@media screen and (max-width: 1500px) {


  .img {
    background-color: var(--dark);
    width: 90%;
    max-width: 90%;
    border-radius: 10px;
    height: calc(90% * 0.5625);
  }


  .title-left {
    text-align: center;
  }

  .desc-left {
    text-align: center;
  }

  .img-title {
    text-align: center;
  }

  .img-desc {
    text-align: center;
  }



  .img-left {
    margin: 0;
  }

  .img-right {
    margin: 0;
    margin-bottom: 3rem;
  }

}


@media screen and (max-width: 1100px) {
  .image-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 4rem 0;
  }

  .img-text {
    width: 100%;
    margin-bottom: 3rem;
    min-width: 100%;
  }

}

@media screen and (max-width: 600px) {

  .img {
    background-color: var(--dark);
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    height: calc(100% * 0.5625);
  }


}

.chooseus {
  margin: 0 auto 15rem auto;
  max-width: 80rem;
  width: 90%;

}

.chooseus-title {
  text-align: center;
  font-size: 30px;
  color: var(--white);
  font-family: Bold;
}

.choose-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  margin: 0 auto 15rem auto;
}

.choose-nav {

  display: flex;
  justify-content: center;
  width: 100%;
  margin: 3rem 0;
}

.choose-tab {
  display: flex;
  align-items: center;
  color: var(--white);
  font-family: Medium;
  padding: 10px 2rem;
  border-bottom: thin solid var(--border);
}

.choose-tab svg {
  margin-right: 5px;
}

.tab-active {
  color: var(--purple);
  border-bottom: thin solid var(--purple);
}

.choose-info {
  margin-top: 2rem;
}

.choose-info {
  display: none;
}

.info-active {
  display: flex;
}

.choose-box {
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  margin: 0rem 3rem;
  width: 15rem;
}

.choose-icon {
  opacity: 0.4;

}

.choose-title {
  font-size: 15px;
  color: var(--white);
  font-family: BOld;
}

.choose-desc {
  text-align: center;
  margin-top: 1rem;
  color: var(--grey);
  font-family: light;
}

@media screen and (max-width: 1200px) {

  .choose-tab {
    display: flex;
    align-items: center;
    font-family: Medium;
    padding: 10px 1rem;
    font-size: 14px;
  }

  .info-active {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    flex-direction: row;
  }

  .choose-box {
    margin-bottom: 3rem;

  }


}


@media screen and (max-width: 800px) {
  .chooseus-title {

    font-size: 25px;

  }

}


.products {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 105rem;
  margin: 10rem auto;
}

.products-title {
  text-align: center;
  width: 100%;
  font-family: Bold;
  color: var(--white);
  font-size: 35px;
  margin-bottom: 5rem;
}

.filters {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  max-width: 50rem;
  margin-bottom: 6rem;
}

.cheats {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.status-game-title {
  color: var(--white);

}

.status_cheat {
  color: var(--white);
}

.filter {
  background: none;
  background-image: url(./images/dropdown.svg);
  background-repeat: no-repeat;
  background-position: right;
  font-family: Medium;
  color: var(--white);
  height: 3rem;
  min-width: 11rem;
  padding: 0 1rem;
  appearance: none;
  outline: none;
  border-radius: 15px;
  border: thin solid var(--border);
  background-color: var(--dark);
  margin-right: 2rem;
}

@media screen and (max-width: 600px) {
  .filters {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;
    width: 90%;

  }

  .filter {

    margin: 1rem 0;

  }

}

.searchbar {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 3rem;
  width: 20rem;
  color: var(--white);
  padding: 0 1rem;
  border: 0;
  border: thin solid var(--border);
  border-radius: 15px;
  background-color: var(--dark);
}

.searchbar svg {
  margin-right: 5px;
}

.search {
  background: none;
  border: none;
  outline: none;
  height: 100%;
  width: 100%;
  color: var(--white);
}

.search::placeholder {
  font-family: Light;
  color: var(--grey);
}


.status-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 60rem;
  width: 90%;
  margin: 10rem auto 15rem auto;
}

.status-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 7rem;
}

.status-game-title {
  font-family: Bold;
  text-align: center;
  font-size: 35px;
  margin-bottom: 1rem;
}

.statuses {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  overflow: hidden;
  border-radius: 1rem;
  outline: thin solid var(--border);
}

.status-card {
  max-width: 70rem;
  width: calc(100% - 4rem);
  padding: 0 2rem;
  display: flex;
  border-bottom: thin solid var(--border);
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  font-family: Bold;
  background-color: var(--dark);
}





.status-card:last-child {
  border: none;
}

.status-cheat {
  color: var(--white);
}

.status-Detected {
  color: red;

}

.status-Updating {
  color: blue;

}

.status-Undetected {
  color: green;

}

.status-Useatownrisk {
  color: orange;

}




@media screen and (max-width: 600px) {

  .status-game-title {
    font-size: 20px;

  }

  .status-cheat {
    font-size: 13px;
  }

  .status-status {
    font-size: 13px;
  }
}


.error-title {
  margin-top: 10rem;
  font-family: BOld;
  color: var(--white);
  font-size: 35px;
  text-align: center;
}

.error-sub {
  font-family: Light;
  color: var(--grey);
  text-align: center;
}



.faq {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 85%;
  max-width: 60rem;
  margin: 10rem auto 15rem auto;
}

.faq-title {
  font-family: BOld;
  color: var(--white);
  text-align: center;
  font-size: 35px;
  margin-bottom: 3rem;
}

.faq-card {
  cursor: pointer;
  background-color: var(--dark);
  width: 80%;
  border-radius: 1rem;
  padding: 0 2rem;
  overflow: hidden;
  transition: max-height ease-in-out 200ms;
  max-height: 4rem;
  border: thin solid var(--border);
  margin: 1rem 0;
}

.faq-card-active {
  transition: max-height ease-in-out 200ms;
  max-height: 15rem;
}

.faq-question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--white);
  font-family: Bold;
  width: 100%;
  height: 4rem;
}

.faq-question svg {
  transition: all ease-in-out 200ms;
}

.faq-card-active svg {
  transform: rotate(180deg);
}

.faq-answer {
  color: var(--white);
  padding: 0 0 1rem 0;
  font-family: Light;
}

@media screen and (max-width: 600px) {
  .faq-question {
    font-size: 12px;
  }

  .faq-answer {
    font-size: 12px;
  }
}

.product-hero {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-height: max-content;
  width: 90%;
  max-width: 70rem;
  flex-wrap: wrap-reverse;
  margin: 12rem auto 0 auto;
}

.product-media-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 30rem;

  margin-right: 3rem;
}


.product-media {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

}

.media-product {
  min-width: 100%;
  max-width: 100%;
  color: var(--white);
  margin: 0;
  padding: 0;
  aspect-ratio: 16/9;
  display: none;
  border-radius: 1rem;
  overflow: hidden;
  background-color: var(--dark);
}

.media-product iframe {
  width: 100%;

  margin: 0;
  padding: 0;
}


.media-product img {
  width: 100%;

  height: 100%;
}

.media-active {
  display: flex;
}

.media-dots {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.dot {
  background-color: var(--dark);
  border-radius: 50%;
  outline: var(--border) thin solid;
  width: 2rem;
  height: 2rem;
  margin: 0 .5rem;
}

.dot-active {
  border: none;
  background-color: var(--white);
}

.product-info {
  height: 100%;
  max-height: 20rem;
  min-height: 21rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.info-container {
  width: fit-content;

}

.info-stats {
  display: flex;
  justify-content: space-between;
  font-family: Bold;

  font-size: 17px;
}

.info-title {
  font-family: Bold;
  color: var(--white);
  margin-top: 10px;
  font-size: 35px;
  width: 100%;
}

.game {
  color: var(--white);
  margin-right: 2rem;
}

.status {
  color: var(--white);
}

.status-undetected {
  color: green;
}

.game-rust {
  color: #c64025;
}

.game-escapefromtarkov {
  color: #939393;
}

.game-rainbowsixsiege {
  color: #939393;
}

.game-dayz {
  color: #939393;
}

.game-spoofer {
  color: #00df12;
}

.game-counterstrike2 {
  color: #e48716;
}

.game-apexlegends {
  color: #c13d44;
}

.game-battlebit {
  color: #939393;
}

.game-modernwarfare {
  color: #83d31b;
}

.game-valorant {
  color: #e83845;
}

.info-prices {
  display: flex;
  margin: auto 0;

  align-items: center;
  flex-wrap: wrap;
  border-radius: 1rem;
  border: thin solid var(--border);
  background-color: var(--dark);
  min-height: 3.5rem;
  margin: .5rem 0;
  padding: .5rem .5rem;
  justify-content: center;
  max-width: 6rem;
}


.info-prices:has(.price:nth-child(2)) {
  max-width: 15rem;
  justify-content: left;
}

.info-prices:has(.price:nth-child(2)) .price {
  padding: 0 1rem;
}

.info-prices:has(.price:nth-child(3)) {
  max-width: 27rem;
  justify-content: left;
}

.info-prices:has(.price:nth-child(3)) .price {
  padding: 0;
}

.info-prices:has(.price:nth-child(4)) {
  min-height: 7rem;
}


.info-prices .price {
  text-align: center;
  font-size: 17px;

  margin: .5rem 0;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: 33%;
  max-width: 33%;
  position: relative;
  color: var(--white);
  font-family: Medium;
}


.price:not(:nth-child(3))::before {
  content: '';
  height: 30px;
  position: absolute;
  background-color: var(--border);
  width: 2px;

  display: flex;
  align-items: center;
  right: 0;

}

.price:last-child::before {
  display: none;
}


.purchase-link {
  margin-top: auto;
}

.purchase button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  border: none;
  width: 27rem;
  background: var(--gradient);
  height: 4rem;
  margin-top: 10px;
  color: var(--white);
  font-size: 17px;
  text-align: center;
  font-family: Bold;
}

.purchase p{
  margin: 0 5px;
  display: flex;
  align-items: center;
}
.purchase p:nth-child(3) svg {
  margin: 0 5px;
  margin-right: 5px;
}

.payment-choices {
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 5px;
  color: var(--white);
  font-family: Light;

}

.stripe {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  width: 27rem;
  background-color: #635BFF;
  height: 4rem;
  margin-top: auto;
  color: var(--white);
  font-size: 20px;
  text-align: center;
  font-family: Bold;

}

.stripe img {
  height: 33px;
  margin-left: .3rem;
}



@media screen and (max-width: 1080px) {

  .product-media-container {
    width: 100%;
    margin-right: 0rem;
  }

  .info-prices {
    display: flex;
    margin: auto 0;
    overflow: hidden;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 1rem;
    border: thin solid var(--border);
    background-color: var(--dark);

    padding: .5rem .5rem;
    justify-content: center;
    max-width: 6rem;
  }

  .product-info {
    min-height: fit-content;
    max-height: fit-content;
  }
}


@media screen and (max-width: 480px) {
  .info-stats {
    font-size: 11px;
  }

  .info-title {
    font-size: 25px;
  }

  .product-info {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }

  .purchase {
    width: 100%;
  }

  .stripe {
    width: 100%;
  }


}



.product-features {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: max-content;
  width: 90%;
  max-width: 60rem;

  margin: 10rem auto 15rem auto;
}

.feature-nav {
  display: flex;
  width: 100%;
}

.feature-nav span {
  padding: 0 .5rem;
  margin: 0 .7rem;
  color: var(--white);
  cursor: pointer;
  font-family: Medium;
  font-size: 20px;

}

.active-nav {
  color: var(--purple) !important;
  border-bottom: thin solid var(--purple);
}

.feature-box {
  border-radius: .5rem;
  border: thin solid var(--border);
  display: none;
  max-width: 100%;
  overflow-y: overlay;
  max-height: 35rem;
  margin-right: auto;
  background-color: var(--dark);
  margin-top: 2rem;

}



.feature-box-active {
  display: flex;
}


.features {
  flex-wrap: wrap;


}

.feature-box-active::-webkit-scrollbar {
  width: 10px;


}

/* Track */
.feature-box-active::-webkit-scrollbar-track {
  background: none;

}

/* Handle */
.feature-box-active::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2rem;

}

/* Handle on hover */
.feature-box-active::-webkit-scrollbar-thumb:hover {
  background: #555;

}

.feature-list {

  list-style-type: none;
  max-width: 11rem;
  margin: 1.5rem 2rem;


}

.feature-list:not(.feature-list-title) {
  color: var(--white);
  word-wrap: break-word;
  font-family: LIght;

}

.feature-list-title {
  color: var(--white);
  margin-bottom: 6px;
  font-family: Bold;
}

.requirement-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2.5rem;
}

.requirement-title {
  font-family: Bold;
  color: var(--white);
  display: flex;
  font-size: 18px;
  align-items: center;
}

.requirement-title svg {
  margin-right: 10px;
}

.requirement-text {
  color: var(--white);
  font-family: Light;
  text-align: center;
  margin-top: 10px;
}

.tos {
  width: 90%;
  max-width: 40rem;
  margin: 10rem auto 10rem auto;
}

.tos header {
  font-family: Bold;
  font-size: 3rem;
  color: var(--purple);
  text-align: center;
}

.tos h1 {
  margin-top: 3rem;
  color: var(--white);
  font-family: Medium;
  font-weight: lighter;
}

.tos p {
  color: var(--grey);
  font-family: Medium;
}

footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  bottom: 0;
  background-color: var(--bg);
  width: calc(100% - 10rem);
  padding: 3rem 5rem 7rem 5rem;
}

.footer-tos {
  width: calc(100% - 10rem);
  display: flex;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  margin: 0 5rem;
  border-top: thin solid var(--light);
  left: 0;
}

.footer-tos  p {
  font-family: Light;
  color: var(--grey);
}

.footer-tos a {
  color: var(--purple);
}

.footer-info {
  width: 30rem;
}

.footer-title {
  color: var(--white);
  display: flex;
  align-items: center;
  font-family: Bold;
}

.footer-title img {
  height: 3rem;
}

.footer-desc {
  font-family: Light;
  color: var(--grey);
}

.footer-lists {
  max-width: 55rem;
  width: 100%;

  display: flex;
  justify-content: space-around;
}

.footer-list {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 22rem;

  list-style-type: none;
}

.footer-list-title {
  color: var(--white);
  font-family: Bold;
  margin-bottom: .7rem;
}

.footer-list li:not(.footer-list-title) {
  font-family: Medium;
  color: var(--grey);
  font-size: 13px;
  margin-bottom: 5px;
}

@media screen and (max-width: 1200px) {
  .footer-info {
    margin-bottom: 2rem;
  }

  footer {
    justify-content: center;
    flex-wrap: wrap;
  }


}


@media screen and (max-width: 850px) {
  .footer-title {

    justify-content: center;

  }


  .footer-list {
    max-height: 200rem;
  }

  .footer-title img {
    height: 3rem;
  }

  .footer-desc {
    text-align: center;
    font-family: Light;
    color: var(--grey);
  }

  .footer-lists {
    max-width: 40rem;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  .footer-list {
    text-align: center;
    width: 12rem;
    margin: 1rem 0;
  }

  .footer-tos {
    width: calc(100% - 5rem);
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    bottom: 0;
    font-size: 12px;
    margin: 0 2.5rem;
    border-top: thin solid var(--light);
    left: 0;
  }


}


.iframe-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}


.iframe-popup-content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 70vh;
  max-width: 30rem;
  width: 100%;
  overflow: hidden;
}


.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none !important;
  color: #fff;
  width: 10rem;
  height: 5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.popup-iframe {
  width: 100%;
  height: 100%;
  border: none; 
}
